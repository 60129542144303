:root {
  --bold: 800;
  --regular: 400;
  --light: 300;
}

body {
  font-family: var(--ff-secondary);
}

.h1 {
  font-family: var(--ff-primary);
  font-size: 4.93rem;
  color: var(--primary-red);
  margin: 2rem 0 1rem;

  @media (max-width: $xl) {
    font-size: 4rem;
  }
}

.h2 {
  font-family: var(--ff-secondary);
  font-size: 4.375rem;

  @media (max-width: $md) {
    font-size: 3.5rem;
  }
}

a {
  color: #000;
  text-decoration: none;
}

p {
  color: var(--gray);
  font-size: 1.25rem;
  line-height: 1.875;
}

label {
  color: var(--gray);
  font-size: 1.25rem;
  line-height: 1.875;
}