#footer {
  background-color: var(--primary-blue);
  padding: 4rem 0;

  .footer__wrapper {
	display: flex;
	align-items: center;
  }
}
.footer__social {
  display: block;
  position: relative;
  width: 40px;
  height: 40px;
  background-size: cover;
  margin-right: 40px;
}

.footer__social--malt {
  background-image: url(../images/svg/malt.svg);
}


.footer__social--linkedin {
  background-image: url(../images/svg/linkedin.svg);
}