
//
//.aa-column {
//  --columns: 24; /* number of columns in the grid system */
//  --width: var(--width-mobile, 0); /* width of the element */
//  --widthOne: calc(calc(calc(var(--width) / var(--columns) * 100%) - 30px) - 30px / var(--columns));
//  --test: calc(calc(calc(var(--width) / var(--columns) * 100%) - 30px) - 30px / var(--columns));
//  --offset: 0;
//  flex-basis: calc(calc(calc(var(--width) / var(--columns) * 100%) - 30px) - 30px / var(--columns));
//  margin: 0 calc(var(--gutter) / 2);
//}
//
//
//@media (min-width: 576px) {
//  .aa-column {
//    --width-tablet: var(--width-mobile);
//    --width: var(--width-tablet);
//  }
//}
//
//@media (min-width: 768px) {
//  .aa-column {
//    --width-desktop: var(--width-tablet);
//    --width: var(--width-desktop);
//  }
//}

$xxxl: 1640px;
$xxl: 1400px;
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;

:root{
  --xxxl: 1640px;
  --xxl: 1400px;
  --xl: 1200px;
  --lg: 992px;
  --md: 768px;
  --sm: 576px;
}

#grid {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;

  .aa-column.aa-column-1 {
    height: 40px;
    background-color: #d6d6d6;
    height: 100vh;
    opacity: .3;
  }
}


.aa-container {
  --max-width: 100%;
  --gutter: 16px;
  --half-gutter: calc(var(--gutter) / 2);
  --columns: 12;
  // Needed because of 0 15px padding from container
  --container-gutter: calc(var(--gutter) / var(--columns));
  // Width for one column
  --width-one: calc((((100% / var(--columns))) - var(--gutter)) - var(--container-gutter));
  position: relative;
  width: 100%;
  max-width: calc(var(--max-width) - var(--gutter));
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  padding: 0 var(--half-gutter);


  // > 576
  @media (min-width: $sm) {
    --max-width: 540px;
  }

  // > 768
  @media (min-width: $md) {
    --max-width: 720px;
  }

  // > 992
  @media (min-width: $lg) {
    --max-width: 960px;
  }

  // > 1200
  @media (min-width: $xl) {
    --max-width: 1140px;
  }

  // > 1400
  @media (min-width: $xxl) {
    --max-width: 1320px;
  }

  // > 1640
  @media (min-width: $xxxl) {
    --max-width: 1545px;
  }
}

.aa-container--nested {
  margin-right: calc(var(--half-gutter) * -1);
  margin-left: calc(var(--half-gutter) * -1);
}

.aa-column {
  --col: 1;
  --width: calc((var(--width-one) * var(--col)) + (var(--gutter) * (var(--col) - 1) ));
  --offset: 0;
  --offset-position: calc(((var(--width-one) + var(--gutter)) * var(--offset)) + var(--half-gutter));
  width: 100%;
  margin: 0 var(--half-gutter) 0 var(--offset-position);

  @for $i from 1 through 12 {

    // > 576
    @media (min-width: $sm) {
      &.aa-column-sm-#{13 - $i} {
        --col: #{13 - $i};
        width: var(--width);
      }
    }

    // > 768
    @media (min-width: $md) {
      &.aa-column-md-#{13 - $i} {
        --col: #{13 - $i};
        width: var(--width);
      }
    }

    // > 992
    @media (min-width: $lg) {
      &.aa-column-lg-#{13 - $i} {
        --col: #{13 - $i};
        width: var(--width);
      }
    }

    // > 1200
    @media (min-width: $xl) {
      &.aa-column-xl-#{13 - $i} {
        --col: #{13 - $i};
        width: var(--width);
      }
    }

    // > 1400
    @media (min-width: $xxl) {
      &.aa-column-xxl-#{13 - $i} {
        --col: #{13 - $i};
        width: var(--width);
      }
    }

    // > 1640
    @media (min-width: $xxxl) {
      &.aa-column-xxxl-#{13 - $i} {
        --col: #{13 - $i};
        width: var(--width);
      }
    }

  }
}


.aa-position {
  --offset-right: 0;
  --offset-right-position: calc(((var(--width-one) + var(--gutter)) * var(--offset-right)) + var(--half-gutter));
}

@for $i from 1 through 12 {

  // > 576
  @media (min-width: $sm) {
    .aa-offset-sm-#{13 - $i} {
      --offset: #{13 - $i};
    }
  }

  // > 768
  @media (min-width: $md) {
    .aa-offset-md-#{13 - $i} {
      --offset: #{13 - $i};
    }
  }

  // > 992
  @media (min-width: $lg) {
    .aa-offset-lg-#{13 - $i} {
      --offset: #{13 - $i};
    }
  }

  // > 1200
  @media (min-width: $xl) {
    .aa-offset-xl-#{13 - $i} {
      --offset: #{13 - $i};
    }
  }

  // > 1400
  @media (min-width: $xxl) {
    .aa-offset-xxl-#{13 - $i} {
      --offset: #{13 - $i};
    }
  }

  // > 1640
  @media (min-width: $xxxl) {
    .aa-offset-xxxl-#{13 - $i} {
      --offset: #{13 - $i};
    }
  }

}

//#test {
//  --col: 4;
//  --offset: 5;
//}

