#contact {

  .h2 {
    color: var(--primary-red);
    margin-bottom: 1rem;

    text-align: center;

    @media (min-width: $md) {
      text-align: left;
    }
  }

  p {
    max-width: 700px;
    margin-bottom: 2rem;
  }
}

#form-contact {
  margin-bottom: 4rem;
  margin-top: 3rem;

  .form-column {
    margin-top: 2rem;
  }

  label {
    display: block;
    text-transform: uppercase;
    color: var(--primary-blue);
  }

  input, textarea {
    display: block;
    position: relative;
    width: 90%;
    border: none;
    border-bottom: 1px solid var(--gray);
    padding: 0 0 0 0;
    margin-top: 1rem;
    font-size: 1.25rem;
    line-height: 1.875;
    font-family: var(--ff-secondary);
    font-weight: 300;

    &:active, &:focus {
      border-bottom-color: var(--primary-yellow);
      outline: none;
    }

    &::placeholder {
      color: var(--gray-light);
      font-weight: 300;
    }
  }

  textarea {
    width: 95% ;
    border-bottom: 1px solid var(--primary-blue);
    background-color: #f3f3f3;
    padding-left: 1rem;
    padding-top: 1rem;
    min-height: 300px;
  }

}

.form-contact__message {
  p {
    margin-top: 0;
    font-size: 1rem;

    &.error {
      color: var(--primary-red)
    }
    &.success {
      color: var(--primary-blue)
    }
  }
}
