#header {

  @media (min-width: $lg) {
    position: fixed;
    display: none;
    align-items: center;
    left: 0;
    top: 0px;
    padding: 26px 0;
    height: 47px;
    width: 100%;
    z-index: 10;
    background-color: var(--primary-blue);
  }
  @media (min-width: $lg) {
    display: flex;
  }

  .main-nav {
    display: flex;
    align-items: center;
  }

  .main-nav__logo {
    display: block;
    height: 47px;
    width: 51px;
    margin-right: 50px;
    background-image: url(../images/svg/logo.svg);
    background-size: cover;
  }

}

.main-nav__link {
  text-transform: uppercase;
  margin-right: 70px;
  color: var(--primary-red);
  font-weight: 300;
  font-size: 1.35rem;
}

.burger-btn {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 45px;
  height: 45px;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  background-image: url(../images/svg/menu1.svg);
  background-size: 28px;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
}

.main-nav.main-nav--mobile {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  z-index: 10;
  transform: translateX(-100%);

  &.is-active {
    transform: translateX(0);
  }

  .main-nav--mobile__background {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transform: translateY(100%);
  }

  .main-nav--mobile__background--1 {
    background-color: var(--secondary-blue);
    z-index: -3;
    transition: transform .4s ease-in-out;
  }


  .main-nav--mobile__background--2 {
    background-color: var(--primary-red);
    z-index: -2;
    transition: transform .4s .15s ease-in-out;
  }


  .main-nav--mobile__background--3 {
    background-color: var(--primary-yellow);
    z-index: -1;
    transition: transform .4s .3s ease-in-out;
  }

  &.is-active {
    .main-nav--mobile__background {
      transform: translateY(0);
    }
  }


  .main-nav__link {
    margin: 2rem 0;
    font-weight: 800;
    opacity: 0;
    transition: opacity .3s .6s ease;
  }

  .main-nav__close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 40px;
    height: 40px;
    border: none;
    background-size: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-image: url(../images/svg/cross.svg);
    opacity: 0;
    transition: opacity .3s .6s ease;
  }

  &.is-active {
    .main-nav__link, .main-nav__close {
      opacity: 1;
    }
  }
}