:root {
  --ff-primary: 'Paytone One', sans-serif;
  --ff-secondary: 'Poppins', sans-serif;
  --primary-blue: #111F30;
  --primary-red: #FD6363;
  --primary-yellow: #EECA3E;
  --secondary-blue: #21CCC8;

  --gray: #535353;
  --gray-light: #b9b9b9;
  --gray-lighter: #eaeaea;

  --scroll-top: 0;
}

body {
  font-family: var(--ff-sans-serif);
  margin: 0;
  padding: 0;
  min-width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}

#root,
.canvas-wrapper {
  width: 100%;
  min-height: 100vh;
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -2;
}

.menu-button {
  --offset-right: 0;
  position: fixed;
  top: 40px;
  right: var(--offset-right-position);
  width: 50px;
  height: 50px;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.d-none {
  display: none !important;
}


img {
  display: block;
  max-width: 100%;
}

.text-sm {
  font-size: 1rem;
}

.position-relative {
  position: relative;
}

.btn {
  position: relative;
  background: none;
  border: none;
  border-bottom: 1px solid var(--primary-blue);
  padding: .3rem 0;
  font-size: 1.1rem;
  text-transform: uppercase;
  cursor: pointer;
}