#home-top {
  position: relative;
  background-color: var(--primary-blue);

  @media (min-width: $lg) {
    background-color: transparent;
  }

  .home-top__bg {
    position: absolute;
    left: 0;
    top: 0;
    min-height: 580px;
    width: 100%;
    background: var(--primary-blue);
    z-index: -1;

    &.webgl-loaded {
      display: none;
    }

    @media (max-width: $xl) {
      min-height: 430px;
    }
  }

  .home-top__content {
    @media (min-width: $lg) {
      padding-left: 35px;
    }
  }

  .h1 {
    margin-top: 308px;
    margin-bottom: 0;

    @media (max-width: $xl) {
      font-size: 4rem;
      margin-top: 150px;
    }

    @media (max-width: $lg) {
      margin-top: 70px;
    }


    @media (max-width: $md) {
      font-size: 3rem;
    }

    .home-top__subtitle {
      display: block;
      margin-top: 5px;
      font-family: var(--ff-secondary);
      font-size: 1.8rem;
      color: var(--primary-yellow);
      font-weight: 300;

      @media (max-width: $md) {
        font-size: 1.5rem;
      }

      span {
        font-size: 1.3rem;
      }
    }
  }

  .home-top__text {
    line-height: 1.5;
    color: var(--gray);
    margin-top: 50px;
    background-color: #fff;
    box-shadow: -280px 0px 0 0px #fff, 280px 0px 0 0px #fff;
    margin-bottom: -1px;
    padding: 30px 0;

    @media (min-width: $lg) {
      margin-top: 142px;
      background-color: transparent;
      box-shadow: none;
      padding: 0 0 70px 0;
      margin-bottom: 1.5rem;
    }

    @media (min-width: $md) {
      max-width: 700px;
    }
  }
}

#home-top-m {
  display: none;
  position: absolute;
  top: 126px;
  width: 600px;
  left: 10px;

  @media (min-width: $lg) {
    display: block;
    top: 90px;
  }

  @media (min-width: $xl) {
    top: 180px;
    left: 0;
  }

  @media (min-width: $xxl) {
    top: 130px;
    left: 0;
  }

}


#services {
  position: relative;

  @media (min-width: $sm) {
    margin-top: 50px;
  }

  @media (min-width: $md) {
    margin-top: 100px;
  }

  @media (min-width: $lg) {
    margin-top: 100px;
  }

  @media (min-width: $xl) {
    margin-top: 100px;
  }

  @media (min-width: $xxl) {
    margin-top: 155px;
  }

  > .aa-container {
    .services__bg {
      position: absolute;
      top: 0;
      left: 50%;
      width: 100vw;
      height: 100%;
      background-color: var(--primary-red);
      z-index: -1;
      transform: translateX(-50%);

      &.webgl-loaded {
        display: none;
      }

      @media (min-width: $md) {
        width: calc( (((100vw - var(--max-width)) / 2) + var(--max-width) + 50px) );
        transform: translateX(-50px);
        left: 0;
      }
    }
  }

  .h2 {
    color: #fff;
    text-align: center;

    @media (min-width: $md) {
      text-align: left;
    }
  }


  .h3 {
    color: var(--primary-blue);
    font-size: 1.5rem;
    font-weight: var(--bold);
    margin-bottom: 40px;
  }

  .service {
    position: relative;
    text-align: center;
    margin-top: 160px;
    padding-bottom: 50px;
    z-index: 1;

    @media (min-width: $xl) {
      margin-top: 160px;
      padding-bottom: 150px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -50px;
      left: 50%;
      width: 90px;
      height: 90px;
      transform: translate(-50%, -100%);
      background-size: cover;
    }
    .service__inner {
      padding: 0 25px;
    }
    p {
      color: var(--primary-blue);
      font-weight: var(--regular);
      line-height: 1.52;
    }
  }

  .service-dev::before {
    background-image: url(../images/svg/dev.svg);
  }
  .service-audit::before {
    background-image: url(../images/svg/audit.svg);
  }
  .service-seo::before {
    background-image: url(../images/svg/seo.svg);
  }
  .service-perf::before {
    background-image: url(../images/svg/perf.svg);
  }

}

#projets {
  position: relative;
  margin-top: -320px;
  padding-top: 370px;
  min-height: 700px;

  .h2 {
    color: #fff;
    text-align: center;

    @media (min-width: $md) {
    	text-align: left;
    }
  }

  .h3 {
    color: var(--primary-blue);
    font-size: 1.5rem;
    font-weight: var(--bold);
    margin-bottom: 40px;
    margin-top: 3rem;
  }

  p {
    display: block;
    margin: 0 auto;
    color: var(--gray);
    font-weight: var(--regular);
    line-height: 1.52;
    width: 100%;
    max-width: 500px;
  }

  > .aa-container {

    .projets__bg {
      position: absolute;
      display: block;
      right: 50%;
      transform: translateX(50%);
      top: -370px;
      height: 740px;
      width: 100vw;
      background-color: var(--primary-blue);
      z-index: -2;

      &.webgl-loaded {
        display: none;
      }

      @media (min-width: $sm) {
        height: 810px;
      }

      @media (min-width: $md) {
        width: calc( ((100vw - var(--max-width)) / 2  ) + var(--max-width) + 50px);
        transform: translateX(50px);
        right: 0;
      }
    }
  }

  .project {
    position: relative;
    text-align: center;
    margin-top: 50px;
    z-index: 1;

    @media (min-width: $md) {
      margin-top: 120px;
    }

    &:nth-child(n+1) {
      .project__inner {
        @media (min-width: $md) {
          padding: 0 30px 0 0;
        }
      }
    }

    &:nth-child(n+2) {
      .project__inner {
        @media (min-width: $md) {
          padding: 0 0 0 30px;
        }
      }
    }

    @media (min-width: $xl) {
      margin-top: 40px;
      padding-bottom: 150px;
    }
    .project__link {
      position: relative;
      display: inline-block;
      color: var(--gray);
      margin-top: 1.5rem;
      padding: 0 30px;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(calc(-50% - 1px));
        width: 15px;
        height: 15px;
        background-image: url(../images/svg/link.svg);
      }
    }
  }
}